.TermsAndConditionsImageContainer {
  /* background-image: url("../../assets/LandingPageImage.png"); */
  background-repeat: no-repeat;
  height: 300px;
  background-size: cover;
  background-position: center;
  width: 100%;
  background: linear-gradient(to right, #32B6C9 10%, #451D70 100%);
  position: relative;
}

.TermsAndConditionsImageContainerTest {
  /* background-image: url("../../assets/LandingPageImage.png"); */
  background-repeat: no-repeat;
  height: 75vh;
  background-size: cover;
  background-position: center;
  width: 100%;
  background: radial-gradient(189.66% 189.66% at 54.03% -21.44%,
      #1a76e4 0%,
      #2accff 62.5%,
      #da8336 93.62%,
      #ee5c9f 100%);
  position: relative;
}
.MainbackgroundColor{
  background-image: linear-gradient(to right, #32B6C9 10%, #451D70 100%);
}
.MainbackgroundColor{
  background-color: 'white';
  border-radius: '50px 50px 0 0';
}
.TermsAndConditionsH1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 58px;
  line-height: 80px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  padding-top: 10%;
}
.TermsAndConditionsH1_1{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 80px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.TermsAndConditionsContentTitle1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  text-align: justify;
}

.landingPageCard {
  height: 286px;
  width: 433.86px;
}
.ContentContainer{
  width: 940px;
  padding-top: 50px;
}
@media only screen and (max-width: 767px) {
.landingPageCard {
  height: 328.8px;
  width: 347.088px;
  }
.TermsAndConditionsContentTitle1 {
 padding: 0 20px 0 20px;
}
}
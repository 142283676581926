:root {
  --light-blue: #2accff;
  --purple: #ee5c9f;
}
.header {
  margin-top: 30px;
  margin-left: 14%;
  margin-right: 15%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
}
.header a:hover {
  color: #ffffff;
}
.flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.communitySlugLoader {
  height: 100vh !important;
}
.samajTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;

  font-size: 24px;

  line-height: 32px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.loginButton {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  /* identical to box height, or 157% */

  text-align: right;
  letter-spacing: 0.2px;
  /* light-text-color */
  color: #ffffff;
  cursor: pointer;
}
.loginButton:hover {
  color: #ee5c9f !important;
  text-decoration: underline;
}

.becomeAMemberButton {
  background: var(--purple) !important;
  border-radius: 25px !important;
  color: #ffffff !important;
  font-family: "Montserrat";

  width: 214px !important;
  height: 52px !important;
  border-color: #347af0 !important;

  font-weight: 700 !important;
  font-style: normal !important;
  font-size: 14px !important;
}
.becomeAMemberButton:hover {
  background: var(--purple);
  color: #ffffff;
}
.aboutTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  /* identical to box height, or 171% */
  text-align: center;
  letter-spacing: 0.2px;
  /* light-text-color */
  color: #ffffff;
}

.aboutTitle:hover {
  color: #ee5c9f !important;
  text-decoration: underline;
}

.landingScreenImage {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;

  z-index: 0;
}

.Title1 {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 40px;
  line-height: 52px;
  color: #ffffff !important;
  display: flex;
  justify-content: end;
  text-shadow: 2px 2px 10px #000000;
}

.subTitle1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #ffffff !important;
  padding-top: 20px;
}
.FindAndRegisterCommunity{
  padding-top: 50px;
}
.subTitleLoginButton {
  width: 220px;
  height: 48px !important;
  /* Main Primary Color */
  background: var(--purple) !important;
  border-radius: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  /* identical to box height, or 157% */
  font-family: "Montserrat";
  text-align: center !important;
  letter-spacing: 0.2px;

  /* light-text-color */

  color: #ffffff !important;
  border-color: var(--purple) !important;
}
.subTitleLoginButton:hover {
  background: var(--purple) !important;
  color: #ffffff !important;
}
.subTitleLearnMoreButton {
  width: 251px !important;
  height: 48px !important;
  border: 1px solid var(--purple);
  border-radius: 14px;
  background: #EE5C9F !important;
  color:  #FFFFFF !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  text-align: center;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  padding: 20px;
  font-family: "Montserrat";
}


.LandingPageCorousel{
Width: "182.83px";
 Height: "394.51px"
}


.communityContainer a.ant-btn {
  padding-top: 0 !important;
}
.landingPageCard {
  box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.07);
  border-radius: 24px !important;
  width: 293.94px;
  height: 231.21px;
}
.LandingPageCardContainer{
  /* width: 940px ; */
  display: flex;
  align-items: center;
  padding: 0 50px;
  z-index: 10000;
}
.cardTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.1px;
  /* Grey */

  color: #f5f5f7;
}
.cardTitle2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: 0.2px;

  /* second-text-color */

  /* color: #f5f5f7 !important; */
}
.cardTitle3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  /* or 143% */
  padding-left: 10px;
  letter-spacing: 0.2px;

  /* second-text-color */

  /* color: #f5f5f7 !important; */
}
.SectionHeading{
  font-family: "Montserrat";
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1.5px;
  text-align: left;
}
.SectionDiscription{
  font-family: "Montserrat";
  font-weight: 600;
  color: #525252;
}

.SectionCommunityEventsButton,
.SectionCommunityBusinessButton,
.SectionCommunityMatrimonialButton,
.SectionCommunityDirectoryButton,
.SectionHelpAndSupportButton{
  width: 193px;
  height: 52px;
  border-radius: 20px;
  background: #F367A7;
  color: white;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
}
.SectionCommunityEventsButton{
  background: #F19C51;
}
.SectionCommunityBusinessButton{
  background: #4593EF;
}
.SectionCommunityDirectoryButton{
  background: #4FD6FF;
}
.SectionHelpAndSupportButton{
  background: #000000;
}
#CommunityManagementPhoto {
  width: 471.8pxpx;
  height: 676.69px;
}
.documentsTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.1px;

  /* text-color */

  color: #252b42 !important;
}

.documentsSubTitle {
  font-family: "Montserrat";

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;
  letter-spacing: 0.2px;

  /* second-text-color */

  color: #737373 !important;
}
.documentsSection {
  height: 435px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.documentsSection .ant-card-bordered {
  border: none !important;
}
.contactNumberSection {
  /* background: #252b42; */
  height: 800px;
}
.contactNumberSection .ant-card-body {
  padding: 0;
}
.CommunityEvents{
  padding-top: 100px;
  width: 940px;
  margin-bottom: 60px;
}
.CommunityBussiness{
  width: 940px;
}
.CommunityEventsFirstColumn{
  display: 'flex';
  align-items: center;
  justify-content: center;
}

#CommunitBusinessMainImage{
  Width: 496px;
  Height: "608.99px";
 margin-right: 50px;
}
.CommunityDirectoryFirstColumn,
.CommunityBusinessFirstColumn{
  padding-left: 50px;
}
.CheckALlBUsinesses{
  padding: 20px 0 0 20px;
}
.CheckALlBUsinesses button{
  width: 193px;
    height: 52px;
    border-radius: 20px;
    background: #4593EF;;
    color: white;
    font-weight: 600;
    font-size: 14px;
    font-style: normal;
}
.contactNumberSection .ant-input {
  background: #f9f9f9 !important;
  border: 1px solid #e6e6e6 !important;
  border-radius: 5px !important;
  height: 50px !important;
}
.contactSectionInputTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.2px;
  /* text-color */
  color: #252b42;
  margin-bottom: 10px;
}
.contactSectionButton {
  background: var(--light-blue) !important;
  border-radius: 100px !important;
  width: 100%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  /* identical to box height, or 157% */

  text-align: center !important;
  letter-spacing: 0.2px;

  /* light-text-color */

  color: #ffffff !important;
  height: 52px !important;
}
.contactNumberSection .ant-card {
  border-radius: 50px !important;
}
.bannerRow {
  position: fixed !important;
  bottom: 40px !important;
  left: 0px !important;
  z-index: 10000 !important;
  width: 100% !important;
}

.bannerMainCol {
  padding: 10px !important;
  width: 98% !important;
}

.bannerInnerRow {
  background: #E5E5E5 !important;
  padding: 10px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
  height: 71px;
}

.bannerHeading {
  font-size: 18px !important;
  font-size: 600 !important;
  color: #000000 !important;
  font-weight: 500;
}

.bannerDescription {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #000000 !important;
}

.AppBannerCloseButton {
  position: absolute;
  top: 0px;
  right: 0px;
}

.CrossButton {
  height: 24px;
  width: 24px;
}
.footerContainer{
  margin-top: 100px;
  background-color: #FFFFFF;
}
.FooterLogo{
  width: 164px; 
  height: 164px; 
  padding: 16.4px 16.4px 16.4px 16.4px;
  border-radius: 65.6px
}
.FooterHeading{
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #252B42;
  padding-bottom: 10px;
  padding: 20px 0 10px 0;
}

.FooterHeadingChild{
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  cursor: pointer;
}
.FooterHeadingChild a {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #252B42;
  letter-spacing: 0.20000000298023224px;
}
.FooterHeadingChild:hover{
  text-decoration: underline;
}
.footer1Section {
  height: 272px;
  display: flex;
  align-items: center;
  background: #ffffff;
  justify-content: center;
}
.footer1Section a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.2px;

  /* second-text-color */

  color: #737373;
}

.footer2SectionTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.2px;
  /* second-text-color */
  color: #737373;
}
.footer2Section {
  background: #fafafa;
  height: 72px;
  margin-top:40px ;
}
.footerLink{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #737373;
  cursor: pointer;
}
.footerLink:hover {
  text-decoration: underline;
}
.hamBurgerMenuSubTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #737373;
  padding: 0;
}
.LandingPageHeader .ant-drawer-content-wrapper {
  height: 324px !important;
}
.LandingPageImageContainer {
background: linear-gradient(to right, #32B6C9 10%,#451D70 100%);
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 180px
}

.CommunityEventsContainer{
  padding-top: 100px;
}
.LandingPageCardResponsive {
  bottom: calc(4vh + -39%);
}
.LandingPageHeadingAndImageContainer{
  padding-top: 150px;
}
.contactNumberSection
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px !important;
  background: #f9f9f9 !important;
  border: 1px solid #e6e6e6 !important;
  border-radius: 5px !important;
  height: 100% !important;
  align-items: center !important;
  display: flex !important;
}
.contactNumberSection .ant-select-selection-search-input {
  height: 100% !important;
  align-items: center !important;
  display: flex !important;
}
.filterOrange {
  filter: invert(64%) sepia(70%) saturate(470%) hue-rotate(336deg)
    brightness(98%) contrast(93%);
}
.filterPurple {
  filter: invert(75%) sepia(49%) saturate(6713%) hue-rotate(299deg)
    brightness(96%) contrast(95%);
}
.filterBlue {
  filter: invert(33%) sepia(74%) saturate(2987%) hue-rotate(201deg)
    brightness(96%) contrast(85%);
}

.DownloadAppContainer {
  height: 257px;
  background: #4fd6ff;
}
.DownloadAppContainerTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */

  text-align: center;
  letter-spacing: 0.1px;

  /* text-color */

  color: #252b42;
}

.blogImage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1327px) {
  .LandingPageCardResponsive {
    bottom: calc(4vh + -52%);
  }
}
@media (max-width: 1100px) {
  .header {
    margin-top: 30px !important;
    margin-left: 8% !important;
    margin-right: 7% !important;
  }
  
}

@media (max-width: 910px) {
  .Title1 {
    font-size: 40px;
  }
}
@media (max-width: 575px) {
  .contactNumberSection .ant-card {
    border-radius: 0px !important;
  }
}
/* Community management */
#CommunityMatrimonialPhoto{
  width: 440px;
  height: 600px;
  margin-left: 65px;
}
.CommunityDirectoryFirstColumn{
  padding-top: 220px;
}
#CommunityDirectroy-button{
  padding: 20px 0 0 20px;
}
#CommunityDirectroy-button button {
  width: 193px;
  height: 52px;
  border-radius: 20px;
  background: #4FD6FF;
  color: white;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
}

.CommunityManagement-row{
  padding-left: 4%;
  padding-right: 4%;
}


/* Help and support */
#HelpandSupport-Heading{
  font-family: "Montserrat";
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; 
  letter-spacing: -1.5px;
}
#HelpandSupport-Information{
font-family: "Montserrat";
font-size: 20px;
font-style: normal;
font-weight: 400;
}
#HelpandSupport-Button Button{
  background-color: rgba(42, 204, 255, 1);
  margin-top: 24px ;
  width: "159px";
  height: 52px;
  border: none;
  border-radius: 24px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.DownloadMobileApplicationContainer{
  background: linear-gradient(to right,#F19657 30%,#EE6693 100%);
  display: flex;
  justify-content: center;
}
.DownloadMobileApplicationHeading{
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
}
.ContactusFormInputField{
  background-color: transparent;
  border-radius: "1px solid #fffff";
  width: 267px;

}
.ContactusFormInputField::placeholder{
  color: #FFFFFF;
}
.ContactusFormSubmitButton{
  width: 182px;
  height: 41px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #ffffff;
  border-radius: 13px;
  font-weight: 700;
}

@media only screen and (max-width: 980px){
.ContactUsFormContainer{
  padding-top: 50px;
}
.DownloadMobileApplicationLogo{
  display: flex;
  justify-content: center;
}
.SectionMainImageContainer{
  display: flex;
  justify-content: center;
}
#CommunityMatrimonialPhoto {
  margin-left: unset;
  padding-top: 50px;
}
.SectionHeading{
  text-align: center;
}
.SectionDiscription {
  padding: 0 40px 0 40px;
  text-align: center;
}
.SectionInformationContainer {
  justify-content: center;
}
#CommunityManagementPhoto {
  width: 370.8px;
  height: 576.69px;
  padding-top: 50px;
}
#CommunitBusinessMainImage {
  Width: 396px;
  Height: "508.99px";
  margin: unset;
}
.CommunityBusinessFirstColumn{
  padding-top: 50px;
  padding-left: unset;
}
.DownloadMobileApplicationHeading {
  text-align: center;
}
}
@media only screen and (max-width: 767px) {
.Title1{
  text-align: center;
  padding: 0 25px;
}
.subTitle1{
  text-align: center;
    padding: 20px 25px;
}
.LandingPageCarouselContainer{
  padding-top: 50px;
 width: 200px;
}
.FindAndRegisterCommunity{
  padding: 0 20px;
  display: flex;
  justify-content: center;
}
.LandingPageCarouselMainContainer {
  display: flex;
  justify-content: center;
}
.FoterDataContainer {
  display: flex;
  justify-content: center;
  text-align: center;
}
}
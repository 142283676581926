:root {
  --light-blue: #2accff;
  --purple: #ee5c9f;
}
.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 29px;
  cursor: pointer;
  /* Primary Text */

  color: #303134;

  /* Gray 6 */
}

.input {
  height: 60px;
}
.label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* line-height: 20px; */
  /* identical to box height */

  /* Secondary Text */

  color: #717579;
}
.addingmember {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Tertiary Text */

  color: #d7d7d7;

  /* Gray 6 */
  padding-top: 8px;
}
.radiobtn {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: var(--light-blue) !important;

  border: 1px solid var(--light-blue) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
.FamilyFound {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;

  /* Primary Text */

  color: #303134;
}
.viewFamilyCard .ant-card-body {
  padding-top: 0px;
}
.memberDivider.ant-divider-horizontal {
  margin: 24px 0px 0px 0px !important;
}
.customScroll {
  /* overflow: hidden; */
  overflow-y: scroll;
  /* height: 100vh ; */
}
.customScroll::-webkit-scrollbar {
  display: none !important;
}
.customLayout::-webkit-scrollbar {
  display: none !important;
}
.memberStyle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 3px;
  color: #828282;
}
.memberName {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.165px;
  color: #131313;
}
.familiyNumber{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 3px;
  color: #828282;
}
.addressLine {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 24px !important;
  /* or 171% */

  /* Gray 2 */

  color: #4f4f4f !important;
}

.FamilyButton {
  width: 244px !important;
  /* height: 60px !important; */
  padding: 18px 0px !important;
  display: flex !important;
  /* text-align: center !important; */
  justify-content: center !important;
  align-items: center !important;
  border-radius: 4px !important;
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 17px !important;
  height: 60px !important;
}

.familyDetailsCard {
  background: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
}

.familyMemberDetailsCard {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #333333 !important;
}

.allButtons {
  background: var(--light-blue);
  align-items: center;
  text-align: center;
  color: #ffffff;
  justify-content: center;
  border-radius: 4px !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: bold !important;
  border-color: var(--light-blue);
}

.allButtons:hover {
  color: var(--light-blue);
  border-color: var(--light-blue);
}

.allButtonsCancel {
  border-color: #e0e0e0;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 4px !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: bold !important;
  background: #e0e0e0;
}

.allButtonsCancel:hover {
  border-color: var(--light-blue);
  color: var(--light-blue);
}

.buttonsAll {
  /* font-family: Poppins !important; */
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;

  /* White */

  color: #ffffff !important;
  background: var(--light-blue) !important;
  border-radius: 4px !important;
}

.newMemberDetails {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  /* Gray 2 */

  color: #4f4f4f;
}

.newMemberSubDetails {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}

.userUpdateInput.ant-input-affix-wrapper > input.ant-input {
  background: #f5f6f8 !important;
  /* border: 1px solid #DEDEDE !important; */
  box-sizing: border-box !important;
  border-radius: 4px !important;
  /* height: 45px !important; */
  /* max-width: 384px; */
}
.userUpdateInput.ant-input-affix-wrapper {
  background-color: #f5f6f8 !important;
}

.OwnerModalTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* Secondary Text */

  color: #717579;
}

.OwnershipButton {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.165px;

  /* Main Primary Color */

  color: #347af0;
  border: none;
  background-color: transparent;
}
.OwnershipTitle1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: -0.165px;
}
.ant-btn-link {
  color: var(--light-blue) !important;
}

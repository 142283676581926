:root {
  --light-blue: #2accff;
  --purple: #ee5c9f;
}
.unselectedButton {
  background: #edf1fb;
  /* border-radius: 4px; */
  font-family: "Montserrat";
  border: 0px solid transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #303134;
  min-height: 48px;
  min-width: 150px;
}

.selectedButton {
  background: #ffffff !important;
  box-shadow: 0px 5px 10px #e3e3e3 !important;
  border: 0px solid transparent !important;
  /* transform: scale(1.05,1.05) !important; */
  border-radius: 4px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;

  font-size: 14px !important;
  line-height: 24px !important;
  color: #717579 !important;
  min-height: 48px !important;
  min-width: 150px !important;
}
.ant-tabs-nav-wrap {
  overflow: visible !important;
}
.Tabs .ant-tabs {
  overflow: hidden !important;
  overflow-x: scroll !important;
}

/* .ant-tabs-tab-active{
    
} */
.selectedButton.ant-btn:focus {
  border-color: #e3e3e3 !important;
}
.hideBottomLine.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  display: none;
}

.SettingsInputLabel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #717579;
}

.SettingsButton {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  height: 60px !important;
  color: #ffffff !important;
  background: var(--light-blue) !important;
  border-radius: 4px !important;
  min-width: 127px !important;
  border-color: var(--light-blue) !important;
}
.SettingsCancelButton {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  height: 60px !important;
  color: #ffffff !important;
  background: #e0e0e0 !important;
  border-radius: 4px !important;
  min-width: 127px !important;
}

.SettingsAccessTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  /* Primary Text */

  color: #303134;
}

.SettingsBorderLine {
  border: 1px solid #e0e0e0 !important;
}

.SettingsCheckboxTitle {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  /* identical to box height */

  color: #717579 !important;
}

.SettingsCheckbox .ant-checkbox-inner {
  border: black !important;
}

.SettingsCancelButton.ant-btn:hover {
  border-color: #e0e0e0 !important;
}

.hidedRow {
  opacity: 0.4 !important;
}
.editDesignationButton,
.editButton {
  background: var(--light-blue) !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;

  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;

  color: #ffffff;
  /* width: 140px; */
  border-color: var(--light-blue);
}
.editDesignationButton.ant-btn-primary:focus {
  background: var(--light-blue) !important;
  border-color: var(--light-blue);
}
/* .editDesignationButton.ant-btn-primary:hover {
  background: var(--light-blue) !important;
  border-color: var(--light-blue);
} */
.unPublishButton,
.deleteButton {
  background: #eb5757 !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  color: #ffffff;

  border: #eb5757 !important;
}

.doc-button {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  color: #ffffff !important;
  background: #0064ff !important;
  border-radius: 4px !important;
  min-width: 127px !important;
  width: fit-content;
}
.unPublishButton.ant-btn-primary:focus {
  background: #eb5757 !important;
}

.publishButton {
  background: #27ae60 !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: #27ae60 !important;
  width: 105px !important;
}
.publishButton.ant-btn-primary:focus {
  background: #27ae60 !important;
}
.emptyScreenAdmin {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}
.emptyScreenAdminCenter {
  display: flex;
  height: 50vh;
  align-items: center;
}

.content {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}
/* .css-g1d714-ValueContainer{
    overflow: scroll !important;

}
.css-b62m3t-container{
    overflow: scroll !important;
}
#react-select-3-listbox.css-26l3qy-menu:nth-child(1){
    background-color: red !important;
    height: 600px !important;
    overflow: scroll !important;
    z-index: 100 !important;
} */

.LoadingIndicatorContainer {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 100 !important;
  user-select: none !important;
}

.LoadingIndicatorContainer::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f5f5f5;
  opacity: 0.4;
  z-index: 1000;
  height: 100% !important;
  user-select: none;
}
.LoadingIndicatorContainer::before,
.IntroLoadingContainer::before {
  content: "";
  opacity: 1 !important;
  background-color: transparent !important;
}
.loading-indicator-sub-container {
  position: relative;
}
.LogoImage {
  height: 25px;
  width: 35px;
  position: absolute;
  transform: rotate(0deg);
  animation-play-state: running;
  z-index: 1000;
}
.logohorizontal {
  width: 25px;
  height: 35px;
}
.dark-blue-logo {
  animation: dark-blue 0.4s linear;
}
.yellow-logo {
  left: 30px;
}
.yellow-logo-animation {
  animation: yellow-animation 0.4s linear;
}
.light-blue-logo {
  left: 9.5px;
  top: -9.5px;
}
.light-blue-animation {
  animation: light-blue 0.4s linear;
}
.pink-logo {
  top: 19.5px;
  left: 9.5px;
}
.pink-animation {
  animation: pink-animation 0.4s linear;
}
/* ------------------------------------------------------------------Intro_Loader------------------------------------------ */
.LoaderIntroImage {
  height: 80px;
  width: 120px;
  position: absolute;
  transform: rotate(0deg);
  animation-play-state: running;
  z-index: 1000;
}
.IntroLoaderCircle {
  animation: intro-circle 4000ms linear 1 forwards;
}
.LoaderIntroLogoHorizontal {
  width: 80px;
  height: 120px;
}
.Loader-Intro-dark-blue-logo {
  left: -85px;
  top: -70px;
}
.Loader-Intro-light-blue-logo {
  left: -60px;
  top: -105px;
}
.Loader-Intro-yellow-logo {
  left: 5px;
  top: -70px;
}

.Loader-Intro-pink-logo {
  top: 5px;
  left: -60px;
}
.Loader-Intro-Light-Blue-Animation {
  animation: intro-light-blue 500ms linear forwards;
}
.Loader-Intro-Dark-Blue-Animation {
  animation: intro-dark-blue 500ms linear forwards;
}
.Loader-Intro-Yellow-Animation {
  animation: intro-yellow 500ms linear forwards;
}
.Loader-Intro-Pink-Animation {
  animation: intro-pink 500ms linear forwards;
}

/* Hello */

@keyframes dark-blue {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    animation-play-state: paused;
  }
}

@keyframes yellow-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes light-blue {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pink-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes intro-dark-blue {
  0% {
    scale: 1;
  }
  100% {
    scale: 0.5;
    rotate: 360deg;
    left: -70px;
    /* top: -px; */
  }
}

@keyframes intro-light-blue {
  0% {
    scale: 1;
  }
  100% {
    scale: 0.5;
    rotate: 360deg;
    top: -85px;
  }
}

@keyframes intro-yellow {
  0% {
    scale: 1;
  }
  100% {
    scale: 0.5;
    rotate: 360deg;
    left: -13px;
  }
}

@keyframes intro-pink {
  0% {
    scale: 1;
  }
  100% {
    scale: 0.5;
    rotate: 360deg;
    top: -13px;
  }
}

@keyframes intro-circle {
  0% {
    rotate: 0deg;
  }
  25% {
    rotate: 90deg;
  }
  50% {
    rotate: 180deg;
  }
  75% {
    rotate: 270deg;
  }
  100% {
    rotate: 360deg;
  }
}

:root {
  --light-blue: #2accff;
  --purple: #ee5c9f;
}
.acceptButton,
.rejectButton {
  background: var(--light-blue) !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  border-color: var(--light-blue);
}
.acceptButton.ant-btn-primary:focus {
  background: var(--light-blue) !important;
}

.rejectButton {
  background: #eb5757 !important;
  border-color: #eb5757 !important;
}
.rejectButton.ant-btn-primary:focus {
  background: #eb5757 !important;
}

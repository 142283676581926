body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden !important;
  overflow-y: scroll !important;
  padding: 0 !important;
  width: unset !important;
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;

  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
/* ::-webkit-scrollbar{
  display: none !important;
} */
.site-layout .site-layout-background {
  background: #fbfbfb;
  border-radius: 2px;
}

.site-content {
  background: #ffffff;
  /* Shadow 2 - Card */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.searchButton {
  /* position: absolute; */
  left: -50px;
  color: #93cfcb !important;
  border: 0;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 2.4px 5px;
}
.zeroPaddingModal .ant-modal-body,
.zeroPaddingModal .ant-modal-header,
.zeroPaddingModal .ant-modal-content {
  border-radius: 20px !important;
  padding: 0px !important;
  z-index: 10;
  overflow: hidden !important;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #93cfcb;
}

::placeholder {
  color: #93cfcbed;
}
.ant-layout {
  background-color: #fbfbfb !important;
  border-radius: 2px !important;
}
/* .react-tel-input .form-control{
      width: 100% !important;
      height: 100% !important;
    }   */

html {
  scroll-behavior: smooth;
  overflow: auto;
  overflow-y: hidden;
}

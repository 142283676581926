.resendButton.ant-btn {
  padding: 0;
}
.otpInput {
  background: #f5f6f8 !important;
  border: 1px solid #dedede !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
.otpFocusInput {
  border: #0064ff !important;
}
div.ImageContainer {
  width: 80%;
  height: 100vh;
  text-align: center;

  left: -37%;
  position: absolute;
  top: -3%;
}
.OtpContainer {
  height: 100vh;
  background: #f3f7fd;
}

.OtpContainer .ant-card-body {
  padding-right: 0;
}

.faqsTitle1 {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  margin-bottom: 24px;
}

.faqsTitle2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  padding-left: 25px;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9 !important;
  border-top: 1px solid #d9d9d9 !important;
}

.AboutUsTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */
  text-align: center; 
  color: #737373;
  padding: 0 20px 0 20px;
}
.ant-collapse-header {
  align-items: baseline !important;
}

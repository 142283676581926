.BlogsButton {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5) ;
    width: 388px;
    top: -146px;
    /* left: 1px; */
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    height: 91px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
}

.blogsimage {
    max-width: 433.86px;
    max-height: 466px;
}
.BlogsButtonText{
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 24px !important;
}
.HowtosCardTitle{
    font-family: "Montserrat";
    font-size: 21px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
}
.HowtosCardDiscription{
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
}
.HowtosCheckoutButton{
    width: 139px;
    height: 49px;
    padding: 14px, 30px, 14px, 29px;
    border: 1px solid #EE5C9F;
    border-radius: 14.01px;
    color: #EE5C9F;
    font-weight: 700;
}
.SelectComunityCardSteps{
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    text-align: center;
}
.SelectComunityCardStepsContainer{
    background-color: #F19C51;
    width: 151px;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.SelectComunityCardStepsContainer1{
    height: 48px;
    border-top: 25px solid transparent;
    border-left: 50px solid #F19C51;
    border-bottom: 25px solid transparent;
}
.SelectComunityCardStepsNumber{
    font-family: "Montserrat";
        font-size: 50px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0px;
        color: #FFFFFF;
        z-index: 500;
}
@media only screen and (max-width: 480px) {
.BlogsButton {
    top: -130px;
    height: 91px;
    width: 280px;
}
}
@media (min-width: 481px)and (max-width: 768px) {
    .BlogsButton {
        top: -82px;
        height: 51px;
        width: 160px;
    }
}
@media (min-width:769px) and (max-width: 1200px) {
    .BlogsButton {
        top: -82px;
        height: 51px;
        width: 200px;
    }
}


:root {
  --light-blue: #2accff;
  --purple: #ee5c9f;
}
.samaj {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #272727;
  padding: 20px;
}
.menuItem {
  color:  #4F4F4F;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.findPeople {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  color: #303134;
}
.header-background {
  background: #f3f7fd;
  border-radius: 2px;
  border-bottom: 2px solid #eeeeee;
  height: unset !important;
  line-height: unset !important;
  padding: 20px 0px;
}

.totalPeople {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 40px;
  background: rgba(79, 214, 255, 0.25);
  padding: 10px;
  border-left: 5px solid var(--light-blue);
  border-radius: 10px 4px 4px 10px;
}
.table {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  text-align: right;
  color: #000000;
  overflow-y: scroll;
}
.AdminTable ::-webkit-scrollbar {
  display: none !important;
}
.ant-table-thead {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  border: 5px solid #d7d7d7;
}
.ant-table-table {
  width: 100%;
  border-radius: 2px 2px 0 0;

  border-collapse: separate;

  border-spacing: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.mainmenu {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding: 20px;
  color: #d7d7d7;
}
.firstname {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #717579;
}
.button-search {
  background: var(--light-blue) !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
}
.ant-btn {
  box-shadow: none !important;
}
.button-search.ant-btn-primary:focus {
  background: var(--light-blue) !important;
}
.ant-badge-count {
  background: #0099ff;
  border-radius: 50%;
}
.search {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

@media (max-width: 767px) {
  .samaj {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.3px;
    color: #272727;
    padding: 20px;
  }
}
.selectedTabsButton {
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(181, 181, 181, 0.42);
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #303134;
}
.unSelectedTabsButton {
  background: #edf1fb;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #717579;
}
.button-matrimonialProfile {
  background: var(--light-blue) !important;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #ffffff;
  border-color: var(--light-blue);
}

.matches {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  /* Primary Text */

  color: #303134;
}
.matchesDetail {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #717579;
}
.MatchesCard,
.ViewMyMatchesCard,
.BusinessCard {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.MatchesCard .ant-card-body {
  padding: 0 !important;
}
.ViewMyMatchesCard .ant-card-body {
  padding: 24px !important;
}
.BusinessCard .ant-card-body {
  padding: 24px !important;
}
.View-matchName {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #303134;
}
.View-matchDate {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}
.View-matchPercentage,
.View-matches {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #219653;
}
.View-matches {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.View-matchAddress {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}
.View-fullProfile {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--light-blue);
  border: none;
}
.cMTabsBckground .ant-tabs-ink-bar {
  height: 0px !important;
  background: transparent !important;
}
.cMTabsBckground .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px solid transparent !important;
}
.cMTabsBckground .ant-tabs-tab {
  padding: 0px !important;
}

.input-header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #717579;
}

.button-Upload {
  background: #0064ff;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* display: flex; */
  align-items: center;
  color: #ffffff;

  /* height: 25px;     */
}

.modal-buttonAdd {
  background: var(--light-blue);
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  /* width: 147px; */
  color: #ffffff;
  /* height: 50px; */
  justify-content: center;
  border-color: var(--light-blue);
}
.modal-buttonAdd:hover {
  border-color: var(--light-blue);
  color: var(--light-blue);
}
.modal-buttonCancel {
  background: #e0e0e0;
  border-color: #e0e0e0;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* height: 50px; */

  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: #ffffff;
  /* width: 147px; */
  /* margin-left: 20px; */
}
.modal-buttonCancel:hover {
  border-color: var(--light-blue);
  color: var(--light-blue);
}
.avtar {
  width: 127px;
  height: 125px;
}
.profileDetails-card {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.profile-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #303134;
}
.profile-birthdate {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #828282;
}
.profile-caste {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}
.profile-address {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}
.ant-page-header-heading-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  background-color: #f5f6f8;
}
.pageHeaderDetails {
  background-color: #f5f6f8;
}
.pageHeaderSubDetails {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #4f4f4f;
}
.ant-page-header {
  /* height: 45px ; */
  padding: 0px 20px;
}
.descriptionDetails {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  color: #4f4f4f;
}
.empty-details {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #828282;
}
.businessInformation {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
}

.customLayout .ant-layout-content {
  height: 100vh !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
  background-color: #ffffff !important;
  margin: 10px;
}
.customLayout ::-webkit-scrollbar {
  display: none !important;
}

.inputLabel {
  /* border: 1px solid #D7D7D7; */
  box-sizing: border-box;

  border-radius: 4px;
  /* height: 50px; */
}

.inputLabel.ant-input-affix-wrapper {
  padding: 0px 2px 0px 8px;
}
.inputLabel.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}
.details {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 9px;
  color: #717579;
}
.formRows {
  padding-top: 10px !important ;
  padding-bottom: 10px !important;
}
.matrimonialProfile {
  font-family: Montserrat;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 20px;
  line-height: 29px;
  /* Primary Text */

  color: #303134;
}
.ant-modal-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;

  /* Primary Text */
  color: #303134;
}

.customScroll {
  overflow: hidden !important;
  overflow-y: scroll !important;
  /* height: 100vh ; */
  background-color: #ffffff !important;
}

.customScroll::-webkit-scrollbar {
  display: none !important;
}

.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  /* font-weight: 500; */
  /* font-size: 16px; */
  /* line-height: 22px; */
  word-wrap: break-word;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #0064ff;
}

.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 0px;
}

.editProfileMenuItem.ant-menu-item {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #717579;
  padding-left: 25px !important;
  background-color: #fbfbfb !important;
}
.ant-steps {
  display: contents;
}
.JobProfileDivider.ant-divider-horizontal {
  margin: 13px 0px !important;
}
.JobProfileCardDivider.ant-divider-horizontal {
  margin: 15px 0px 15px 0px;
}

.BusinessEstablishedDate {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  /* Gray 3 */

  color: #828282 !important;
}
.BusinessEstablishedDateData {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  /* Gray 3 */

  color: #828282 !important;
}
.BusinessLocation {
  font-family: "Montserrat" !important;

  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  /* or 171% */

  /* Gray 2 */

  color: #4f4f4f !important;
}

.BusinessLocationData {
  font-family: "Montserrat" !important;

  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  /* or 171% */

  /* Gray 2 */

  color: #4f4f4f !important;
}

.BusinessProfileAvatar .ant-avatar-string {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
  font-size: 77px !important;
}
.sidebarSamajTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #272727;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: default;
  height: 60px !important;
}

.sliderMainMenuText {
  color: var(--Gray-3, #828282);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.sliderMainMenuText.ant-menu-item-active {
  color: #d7d7d7;
}
.headerText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  /* Primary Text */

  color: #303134;
}

.BusinessTabs .ant-tabs {
  overflow: visible !important;
}
.findOtherPeopleLayout {
  height: 100vh !important;
}
/* .findOtherPeopleLayout .ant-menu-item-selected {
  background: rgba(79, 214, 255, 0.1);
  border-left: 10px solid var(--light-blue);
} */
.findOtherPeopleLayout .ant-menu-item-selected {
  background: rgba(79, 214, 255, 0.1);
  position: relative;
}
.findOtherPeopleLayout .ant-menu-item-selected::before {
  content: "" !important;
  border-left: 10px solid var(--light-blue) !important;
  position: absolute !important;
  left: 0px !important;
  width: 10px !important;
  height: 100% !important;
}
.findOtherPeopleLayout .ant-menu-item-selected::after {
  border-right: none;
  /* border-left: 10px solid #2accff; */
}
.NewInputDesign {
  width: 100% !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  background: #f9f9f9;
  border: none;
  border-bottom: 2px solid #e6e6e6 !important;
  /* height: 40px !important; */
}
.NewInputDesign .ant-select-selector {
  background: #f9f9f9 !important;
  border: none !important;
}
.NewInputText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #252b42;
}
/* @keyframes arrow-Icon-Animation {
  0% {
    color: green;
    transform: translateX(-10px);
  }
  50% {
    color: orange;
  }
  100% {
    color: blue;
  }
}

.arrowIcon:hover {
  animation: 4s linear 1 arrow-Icon-Animation;
} */
.ant-menu .ant-menu-root .ant-menu-inline .ant-menu-light {
  height: 100% !important;
  background: #ffffff !important;
}

.MatrimonialWorkTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  /* Secondary Text */

  color: #717579;
}
.MatrimonialViewMyMatchesSelect {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
}
.ImageViewMatrimonial {
  width: 100% !important;
}
.ImageViewMatrimonial .image-gallery-image {
  width: inherit !important;
  height: 200px !important;
  object-fit: cover !important;
}
.fullscreen .image-gallery-slide img {
  min-height: 100vh;
  object-fit: inherit !important;
  /* object-fit: scale-down !important; */
  /* object-fit: fill !important; */
  /* object-position: inherit !important;
  image-rendering: auto !important;
  image-resolution: initial !important;
  inset: inherit !important; */
}


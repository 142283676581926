.DocumentsTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;

/* Primary Text */

color: #303134;
}
.DocumentsTitle1,.DocumentsTitle2{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
color: #828282;
white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
.DocumentsTitle2{
    font-weight: 500;
}

.DocumentsButton{
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
/* identical to box height, or 171% */


color: #0064FF;
padding: 0;
}
.DocumentsTitle3{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/* identical to box height */


/* Secondary Text */

color: #717579
}
.MeetingTitle{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;

/* Primary Text */

color: #303134
}
.MeetingTitle1,.MeetingTitle2{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 24px;
/* or 171% */


/* Gray 2 */

color: #4F4F4F;
}
.MeetingTitle2{
    font-weight: 400;
}
.DocumentCard{
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12);
border-radius: 4px;
background: #FFFFFF;
}
.Heading{
    border: 1px solid #D7D7D7;
border-radius: 4px;
}
.pdfZoomOut{
    margin-right: 10px;
}
.pdfRefresh{
    display: none;
}
.pdfZoomOut::after{
    content: "Hello";
}
/* .material-icons{
    display: none;
} */
.pdfZoomIn{
    margin-right: 10px;
}
.pdfZoomIn::after{
    content: "Zoom In";
}
.pdfnextPage{
    margin-left: 10px;
}
.rpv-default-layout__container{
    margin-top: 20px !important;
}
.InformationContainer{
    align-items: center;
    height: 100%;
}
.InformationContainer .ant-empty-image{
    height: 50px !important;
    color: #828282 !important
}
.communityTitle {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 29px !important;
  letter-spacing: 0.3px !important;

  color: #272727 !important;
}
.communityBtn .ant-btn {
  border: 1px solid #0064ff !important;
}

.communityRadioBtn {
  font-family: Montserrat !important;

  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px;
  height: 56px !important;
  /* width: 131px !important; */

  /* identical to box height */

  letter-spacing: 0.4375px !important;

  /* Primary Color */

  color: #272727 !important;
  border-radius: 4px !important;
  background: #f5f6f8;
}

.communityBtn {
  background: #f5f6f8 !important;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
}
.communityContent {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;

  font-size: 14px;
  line-height: 23px;
  /* or 163% */
  letter-spacing: 0.2625px;

  color: rgba(39, 39, 39, 0.85);
}
.headerTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #272727 !important;
}

.pageContent {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;

  /* or 163% */
  letter-spacing: 0.2625px;

  color: rgba(39, 39, 39, 0.85);
}
#back {
  align-items: center;
}
#backbtn {
  padding: 13px;
}

.communityTitle1 {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
}

.communityRadioBtn:hover {
  color: #ffffff !important;
  background: #4fd6ff !important;
}
.communityRadioBtn1 {
  font-family: Montserrat !important;

  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px;
  /* margin: 2px 2px; */
  /* identical to box height */

  letter-spacing: 0.4375px !important;

  /* Primary Color */

  color: #272727 !important;
  border-radius: 4px !important;
  background: #f5f6f8;
}
.communityRadioBtn1:hover {
  color: #2accff !important;
}
.communityRadioBtn1:focus {
  color: #2accff !important;
}

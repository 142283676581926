.LayoutContainer{
    height: 100vh !important;
    padding: 80px 60px !important;
}
.LayoutTitle{
    font-family: 'Montserrat' !important;
    font-size: 30px !important;
    font-weight: 500 !important;
    line-height: 36.57px !important;
    text-align: left !important;
}
.FormInputLabel{
    font-family: "Montserrat" !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19.5px !important;
    text-align: left !important;
}
.TableTitle{
    font-family: "Montserrat" !important;
    color: #7B7B7B !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-align: left !important;
}
.TableDataText{
    font-family: "Montserrat" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    text-align: left !important;

}
.LayoutShortDetails{
    font-family: 'Montserrat' !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 17.07px !important;
    text-align: left !important;
}
.inputFeildDiscription{
    font-family: 'Montserrat' !important;
    color: #7B7B7B !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 17.07px !important;
    text-align: left  !important;

}
.uploadFileText{
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;

}
.uploadFileSubText{
  font-family: "Montserrat";
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.fileName{
  font-family: "Montserrat";
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}

.seating-chart {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .section {
    display: flex;
    align-items: center;
    padding-right: 20px; /* Adds padding to ensure the icons stay inside the boundary */
  }
  
  .section-name {
    /* width: 30px; */
    margin-right: 10px;
    text-wrap: nowrap !important;
  }
  
  .seats-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .seats {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    flex-grow: 1;
    padding-right: 20px; 
  }
  
  .seats::-webkit-scrollbar {
    display: none;
  }
  
  .seat {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 20px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 5px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .seat.occupied {
    background-color: #555;
    color: #fff;
  }
  
  .seat.available {
    background-color: #fff;
    color: #000;
  }
  
  .icons {
    display: flex;
    align-items: center;
    flex-shrink: 0; 
    margin-left: 10px; 
    background-color: #fff; 
    padding-left: 10px;
  }
  
  
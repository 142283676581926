.IdCard {
  /* background: rgba(255, 255, 255, 0.4) !important; */
  /* height: 400px; */
  background-image: url(../../assets/IdCardImage.png);
  border-radius: 0;
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.IdCardImage {
  width: 200px;
  height: 205px;
  border-radius: 500px;
}
.centerDiv {
  padding: 45px;
  /* border: 1px solid #ffffff; */
  border-radius: 500px;
  /* border-style: dashed; */
  /* background: linear-gradient(
    180deg,
    rgba(247, 174, 208, 1) 44%,
    rgba(145, 190, 245, 1) 96%,
    rgba(184, 193, 207, 1) 100%,
    rgba(0, 0, 0, 1) 100%
  ); */
  position: relative;
  margin-top: 100px;
  background: rgba(255, 255, 255, 0.1);
}
.IdCardMainContainer .ant-modal-body {
  padding: 0 !important;
}
.NameTitle,
.NameTitle1{
  position: absolute;
  top: -19px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 38%; */
  width: 200px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 39px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}
.NameTitle1 {
  /* height: 53%; */
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  top: 19px;
}
.NameTitle2 , .NameTitle3{
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 44px;
line-height: 59px;
text-align: center;
margin-top: -39px;
color: #FFFFFF
}
.NameTitle3{
  font-size: 20px;
  line-height: 29px;
margin-top: unset;
  font-weight: 600;
}
.NameTitle4 , .NameTitle5{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
margin-top: 25px;
text-align: center;
color: #FFFFFF
}
.NameTitle5{
  font-weight: 500;
margin-top: unset

}
.PhoneNumberTitle{
  font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 30px;
/* identical to box height, or 125% */
padding: 37px 0px;
text-align: center;
color: #FFFFFF
}
.PhoneNumberTitleContainer{
  margin-top: 41px;
  background: rgba(255, 255, 255, 0.4);
width:100%
}
